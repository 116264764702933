import React from 'react';
import './footer.css'; // Create this CSS file to style the footer

const Footer: React.FC = () => {
  return (
    <div id="footerContent">
      <div id="footerNotes">
        <div style={{ borderTopStyle: 'solid', borderTopColor: '#323232', backgroundColor: '#292929', width: '100%', height: 'auto', position: 'relative', left: 0, right: 0, top: '40vh' }}>
          <div className="centerDiv" style={{ lineHeight: '80%', paddingBottom: '2vh', paddingTop: '2vh', width: '100%' }}>
            <p style={{ fontSize: '11px', lineHeight: '100%' }}>
              <a className="footerLinks" href="https://store.steampowered.com/app/1446340/Shaype/">Steam</a> | <a className="footerLinks" href="https://www.patreon.com/sinkingshipstudios">Patreon</a> | <a className="footerLinks" href="https://discord.gg/UqcTEd9">Discord</a> | <a className="footerLinks" href="https://twitter.com/SinkingShipLA">Twitter</a>
            </p>
            <p style={{ fontSize: '11px', lineHeight: '100%' }}>Privacy | Legal | Terms | Cookies</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
