import React from 'react';

// Define the types for your props
interface ArtistPopupProps {
  artist: {
    name: string;
    title: string;
    image: string;
    contacts: Array<{
      icon: string;
      source: string;
    }>;
  };
  onClose: () => void; // Function to close the popup
}

const ArtistPopup: React.FC<ArtistPopupProps> = ({ artist, onClose }) => {
  return (
    <div className="artist-popup">
      <div className="popup-content">
        <button className="close-popup" onClick={onClose}>X</button>
        <h2>{artist.name}</h2>
        <h3>{artist.title}</h3>
        <img src={artist.image} alt={artist.name} />
        <div className="contacts">
          {artist.contacts.map((contact, idx) => (
            <div key={idx} className="contact">
              <i className={`fa-brands fa-${contact.icon}`}></i>
              <span>{contact.source}</span>
            </div>
          ))}
        </div>
        <section className="paragraph center">
            <a href="./about-us" className="btn cta_btn">Meet the Team</a>
        </section>
      </div>
    </div>
  );
};

export default ArtistPopup;
