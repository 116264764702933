import React, { useEffect, useState } from 'react';

//import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import FlexboxLayout from './components/flexboxLayout';
import Preloader from 'components/preloader';
//import './App.css';

import NavBarTop from 'components/navBar_top'; // Import the Footer component
import NavBarMain from 'components/navBar_main'; // Import the Footer component
import Footer from 'components/footer'; // Import the Footer component
import HeroCarousel from 'components/carousel';
import MusicPlayer from 'components/music';
import Gallery from 'components/gallery';

import ScrollButton from 'components/scrollButton'; // Import the reusable button

import ShaypeLogo from 'assets/images/home_images/SHAYPE_reduced.webp'; // Import the Footer component
const HeroVideo_MP4_high = require('assets/videos/HeroVid-Hi-Web.mp4');
const HeroVideo_WEBM_high = require('assets/videos/HeroVid-Hi-Web.mp4');
const HeroVideo_MP4_low = require('assets/videos/HeroVid-Low-Web.mp4');
const HeroVideo_WEBM_low = require('assets/videos/HeroVid-Low-Web.mp4');
const HeroVideoThumb = require('assets/images/home_images/HeroVideoThumb.jpg');



//FIX PRELOADER

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  //const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulate page-specific loading delay of 2 seconds
    return () => clearTimeout(timer);
  }, []);


  return (
  <>
    {loading ? (
      <Preloader />
    ) : (


    <div className="App">

    <NavBarTop theme="transparent" /> {/* Add the Navbar component here */}
    <NavBarMain theme="transparent" />{/* Add the Navbar component here */} 
      

      <div className="hero-video-container">
      <div className="video-overlay"></div>
        <video autoPlay loop muted playsInline className="background-video" poster={HeroVideoThumb}>
          {/* Low quality videos */}
          <source src={HeroVideo_WEBM_low} type="video/webm" media="(max-width: 768px)" />
          <source src={HeroVideo_MP4_low} type="video/mp4" media="(max-width: 768px)" />

          {/* High quality videos */}
          <source src={HeroVideo_WEBM_high} type="video/webm" />
          <source src={HeroVideo_MP4_high} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <section className="content">
        <div className="overlay center">
          <img src={ShaypeLogo} alt="Shaype Logo" className="hero-logo" />
        </div>
        <ScrollButton label="Explore" targetId="summary" />
      </section>
      
      <section className="paragraph info">
        <p id="summary" style={{fontSize: '16px', lineHeight: '150%'}}>
          <b>Shaype</b> is a fantasy role-playing game with an emphasis on{' '}
          <i>player dictated character creation</i>, <i>combat playstyles</i>,{' '}
          <i>dialogue</i>, and <i>story outcomes</i>. The ability for the player to
          create and develop their own character and world is at the heart of Shaype’s
          design, presenting diverse role-playing and alignment options.
        </p>
      </section>

      

      <section className="list info">
      <h1 style={{ fontSize: '2rem' }}>Our Vision:</h1>
      <p>
        With Shaype, we wish to create a true role-playing game. Our focus in development is for the player to feel
        as though they exist in an engaging world where their decisions matter.
      </p>
      <br />
      <br />
      <ul>
        <li>For combat, we are developing varied weapons and divisions of Magic which will each possess their own skill trees and abilities.</li>
        <br />
        <li>We want gameplay to be multifaceted and reflective of the player, with significant world-changing options in dialogue, playstyle, and quests that are driven by the skillset and choices of your character.</li>
        <br />
        <li>A major goal for Shaype is to provide an engaging setting and atmosphere. Despite the friendly art style, Shaype will explore dark/adult themes, allow for diverse character alignment options, and pose morally grey situations in a violent land.</li>
        <br />
        <li>In Shaype, we also want to provide a challenging experience that rewards the skill level of the player. Our combat mechanics are designed to be easy to learn but hard to master, testing the ability of the player to find openings to strike.</li>
      </ul>
    </section>

    <section className="paragraph center">
      <a href="https://discord.gg/ZADcjxhw4q" className="btn cta_btn" target="_blank" rel="noopener noreferrer">Join Our Discord</a>
    </section>


    {/* <div id="discordInvite" style={{ display: 'block' }}>
      <h2 style={{ textAlign: 'center', marginTop: 50}}>Join Our Discord!</h2>
      <div id="discordContent">
        {isMobile ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <a href="https://discord.gg/UqcTEd9" target="_blank" rel="noopener noreferrer" style={{ fontSize: '24px', color: '#7289DA' }}>
              <i className="fab fa-discord"></i>
            </a>
          </div>
        ) : (
          <div style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
            <iframe
              src="https://discordapp.com/widget?id=589181117731569901&theme=dark"
              style={{ width: '100%', minWidth: '250px', textAlign: 'center', marginLeft: '0', marginRight: '0' }}
              height="500px"
              
              frameBorder="0"
              title="Join the Sinking Ship Studios Discord"
            ></iframe>
          </div>
        )}
      </div>
    </div>
      */}

    <section className="music info">
        <h1 style={{ marginTop: 50}}>Listen</h1>
        <p>
            Most recent track from our game
        </p>
        <hr className="hr-short" />
        <br></br>

        <section className="paragraph info"> <MusicPlayer startIndex={0}/> </section>

        <section className="paragraph center">
            <a href="./Music" className="btn cta_btn">Listen More....</a>
        </section>
    </section>
    {/* <FlexboxLayout /> */} {/* used to display float grid elements */}
    {/*  <HeroCarousel /> */}
    {/*  <Gallery /> */}

    <Footer /> {/* Add the Footer component here */}
    </div>

  )}
  </>
);
};

export default App;

 