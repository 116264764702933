import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import FlexboxLayout from './components/flexboxLayout';
//import Preloader from './components/preloader';
import './App.css';



import Footer from 'components/footer'; // Import the Footer component
//import HeroCarousel from 'components/carousel';
//import MusicPlayer from 'components/music';
//import GalleryIMG from 'components/gallery';

import Home from './pages/Home';   // Now coming from the pages folder
import Gallery from './pages/Gallery';
import Listen from './pages/Listen';
import Team from './pages/Team';
import Legal from './pages/Legal';

//import ShaypeLogo from 'assets/images/home_images/SHAYPE.png'; // Import the Footer component
//const HeroVideo_MP4 = require('assets/videos/HeroVideo.mp4');
//const HeroVideoThumb = require('assets/images/home_images/HeroVideoThumb.jpg');




const App: React.FC = () => {
  return (
    <Router>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/music" element={<Listen />} />
        <Route path="/about-us" element={<Team />} />
        <Route path="/terms-and-conditions" element={<Legal />} />
      </Routes>

    </Router>
    
  );
};

export default App;