// src/pages/Team.tsx
import React from 'react';

import Footer from 'components/footer'; // Import the Footer component

import NavBarTop from 'components/navBar_top'; // Import the Footer component
import NavBarMain from 'components/navBar_main'; // Import the Footer component
import TeamDetails from 'components/teamDetails'; // Adjust the import path as necessary

const mccoyArt = require('assets/images/music_images/mccoy.jpg');
const tyArt = require('assets/images/music_images/tyrelleProfilePic.jpeg');


const Team: React.FC = () => {
  
  const tyrelle = {
    name: "Tyrelle",
    title: "Artist",
    image: tyArt,
    contacts: [
      { icon: "twitter", source: "twitter.com/tyrelle" },
      { icon: "linkedin", source: "linkedin.com/in/tyrelle" },
    ],
  };

  const mccoy = {
    name: "McCoy",
    title: "Artist",
    image: mccoyArt,
    contacts: [
      { icon: "twitter", source: "twitter.com/shaype" },
      { icon: "linkedin", source: "linkedin.com/in/shaype" },
    ],
  };

  const cody_j = {
    name: "FyreNight",
    title: "Artist",
    image: mccoyArt,
    contacts: [
      { icon: "twitter", source: "twitter.com/shaype" },
      { icon: "linkedin", source: "linkedin.com/in/shaype" },
    ],
  };

  const chris_f = {
    name: "Christopher",
    title: "Artist",
    image: mccoyArt,
    contacts: [
      { icon: "twitter", source: "twitter.com/shaype" },
      { icon: "linkedin", source: "linkedin.com/in/shaype" },
    ],
  };

  const stephen_e = {
    name: "Stephen",
    title: "Artist",
    image: mccoyArt,
    contacts: [
      { icon: "twitter", source: "twitter.com/shaype" },
      { icon: "linkedin", source: "linkedin.com/in/shaype" },
    ],
  };



  return (
    <div className="App">
        <NavBarTop theme="solid" /> {/* Add the Navbar component here */}
        <NavBarMain theme="solid" />{/* Add the Navbar component here */} 

        <section className="paragraph info">
            <h1>Meet the Team</h1>
            <p>Add Tye, McCoy, Chris, Stephen, Cody</p>
            <hr className="hr-short" />
            <br></br>
            <p style={{ fontSize: 40 }} >WIP come back later....</p>
        </section>
        
        <section className="team-container">
          {/* Call the ArtistDetails component for each artist */}
          <TeamDetails artist={tyrelle} />
          <TeamDetails artist={mccoy} />
          <TeamDetails artist={cody_j} />
          <TeamDetails artist={chris_f} />
          <TeamDetails artist={stephen_e} />
        </section>
        

        <Footer /> {/* Add the Footer component here */}
    </div>
  );
};

export default Team;
