import React, { useEffect, useRef } from 'react';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import './gallery.css';

import FortressDawn_HD from 'assets/images/gallery_images/Fortress_Dawn.jpg';
import FortressDawn_Low_WEBP from 'assets/images/gallery_images/thumbnails/Fortress_Dawn_thumb.jpg';
import FortressDawn_Low_JPG from 'assets/images/gallery_images/thumbnails/Fortress_Dawn_thumb.jpg';

import GearCropped_HD from 'assets/images/gallery_images/GearCropped.jpg';
import GearCropped_Low_WEBP from 'assets/images/gallery_images/GearCropped.jpg';
import GearCropped_Low_JPG from 'assets/images/gallery_images/GearCropped.jpg';
import GrasslandsSunset_HD from 'assets/images/gallery_images/grasslands_Sunset.jpg';
import GrasslandsSunset_Low_WEBP from 'assets/images/gallery_images/grasslands_Sunset.jpg';
import GrasslandsSunset_Low_JPG from 'assets/images/gallery_images/grasslands_Sunset.jpg';

const Gallery: React.FC = () => {

    const galleryRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Lazy load observer setup
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const imgElement = entry.target as HTMLImageElement;
              console.log(`Trying to load image: ${imgElement.alt}`);
              imgElement.src = imgElement.dataset.src || '';
    
              imgElement.onload = () => {
                console.log(`Load complete for image: ${imgElement.alt}`);
                imgElement.classList.add('loaded'); // Add class to mark it as loaded
              };
    
              observer.unobserve(imgElement); // Stop observing once loaded
            }
          });
        }, {
          threshold: 0.1,
        });
    
        // Attach the observer to each image
        const imgElements = galleryRef.current?.querySelectorAll('img[data-src]');
        imgElements?.forEach((img) => observer.observe(img));
    
        // Initialize Fancybox
        Fancybox.bind('[data-fancybox="gallery"]', {});
    
        return () => {
          Fancybox.destroy(); // Cleanup on unmount
          observer.disconnect(); // Cleanup the observer
        };
      }, []);

    return (
    <div className="gallery-container" ref={galleryRef}>
        {/* Using picture for WebP fallback */}
        <a data-fancybox="gallery" href={FortressDawn_HD} className="large-image">
            <picture>
                <source type="image/webp" data-src={FortressDawn_Low_WEBP} />
                <img data-src={FortressDawn_Low_JPG} alt="Fortress Dawn" />
            </picture>
        </a>

        <a data-fancybox="gallery" href={GearCropped_HD}>
            <picture>
                <source type="image/webp" data-src={GearCropped_Low_WEBP} />
                <img data-src={GearCropped_Low_JPG} alt="Gear Cropped" />
            </picture>
        </a>
        <a data-fancybox="gallery" href={GrasslandsSunset_HD}>
            <picture>
                <source type="image/webp" data-src={GrasslandsSunset_Low_WEBP} />
                <img data-src={GrasslandsSunset_Low_JPG} alt="Grasslands Sunset" />
            </picture>
        </a>
    </div>
    );
    };

export default Gallery;
