// src/pages/Legal.tsx
import React from 'react';

import NavBarTop from 'components/navBar_top'; // Import the Footer component
import NavBarMain from 'components/navBar_main'; // Import the Footer component
import Footer from 'components/footer'; // Import the Footer component


const Legal: React.FC = () => {
  return (
    <div className="App">
        <NavBarTop theme="solid" /> {/* Add the Navbar component here */}
        <NavBarMain theme="solid" />{/* Add the Navbar component here */} 

        <section className="paragraph info">
            <h1 id="privacy">Privacy</h1>
            <p>add this stuff here</p>
            <hr className="hr-short" />
            <br></br>
            <p style={{ fontSize: 40 }} >WIP come back later....</p>
        </section>

        <section className="paragraph info">
            <h1 id="legal">Legal</h1>
            <p>add this stuff here</p>
            <hr className="hr-short" />
            <br></br>
            <p style={{ fontSize: 40 }} >WIP come back later....</p>
        </section>

        <section className="paragraph info">
            <h1 id="terms">Terms</h1>
            <p>add this stuff here</p>
            <hr className="hr-short" />
            <br></br>
            <p style={{ fontSize: 40 }} >WIP come back later....</p>
        </section>


        <section className="paragraph info">
            <h1 id="cookies">Cookies</h1>
            <p>add this stuff here</p>
            <hr className="hr-short" />
            <br></br>
            <p style={{ fontSize: 40 }} >WIP come back later....</p>
        </section>
        
        

        <Footer /> {/* Add the Footer component here */}
    </div>
  );
};

export default Legal;
