import React, { useState, useEffect, CSSProperties } from "react";
import './navBar.css'; // Assuming your CSS file is named Navbar.css
import logo from '../assets/icons/img/nav_homebutton_wide_noBoat.png';

const NavBar = ({ theme = "transparent" }) => {
  const [navTheme, setNavTheme] = useState(theme);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setNavTheme("solid");
    } else {
      setNavTheme(theme);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [theme]);

  // Define a custom style type to include the --i CSS variable
  interface CustomStyle extends CSSProperties {
    '--i'?: number;
  }

  const navItemsData = [
    { label: "Home", url: "/" },
    { label: "Steam", url: "https://store.steampowered.com/app/1446340/Shaype/", external: true},
    { label: "Gallery", url: "/gallery" },
    { label: "Music", url: "/music" },
  ];

  const navItems = navItemsData.map((item, index) => (
    item.external ? (
    <a
      href={item.url}
      className="nav-item center"
      key={index}
      style={{ '--i': index } as CustomStyle}
      target="_blank"
      rel="noopener noreferrer"
    >
      {item.label}
    </a>
    ) : (
      <a
      href={item.url}
      className="nav-item center"
      key={index}
      style={{ '--i': index } as CustomStyle}
    >
      {item.label}
    </a>
  )
  ));

  return (
    <header className={`navbar-container-sticky ${navTheme}`}>
      
      <div className="navbar-bottom-row">
        <a href="#" className="logo">
          <img src={logo} alt="Logo" />
        </a>
        <input type="checkbox" id="check" />
        <label htmlFor="check" className="icons">
          <i className="fas fa-bars" id="menu-icon"></i>
          <i className="fas fa-times" id="close-icon"></i>
        </label>
        
        <nav className="navbar-menu">
          {navItems}
        </nav>
        
      </div>
    </header>
  );
};

export default NavBar;
