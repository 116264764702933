// ScrollButton.tsx
import React from 'react';
import { smoothScrollTo } from './scrollUtils'; // Import the utility function

interface ScrollButtonProps {
  label: string;
  targetId: string;
}

const ScrollButton: React.FC<ScrollButtonProps> = ({ label, targetId }) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault(); // Prevent the default anchor behavior
    smoothScrollTo(targetId); // Call the scroll function
  };

  return (
    <a href={`#${targetId}`} className="btn cta_btn" onClick={handleClick}>
      {label}
    </a>
  );
};

export default ScrollButton;
