import React, { useEffect, useRef, useState, useMemo } from 'react';
import WaveSurfer from 'wavesurfer.js';

import ArtistPopup from './artistPopup'; // Import your ArtistPopup component


import GrasslandTye_ogg from '../assets/music/Grasslands-Tyrelle.ogg';
import GrasslandTye_mp3 from '../assets/music/Grasslands-Tyrelle.mp3';
import BattleTye_ogg from '../assets/music/BattleMusic-Tyrelle.ogg';
import BattleTye_mp3 from '../assets/music/BattleMusic-Tyrelle.mp3';
import HubTye_ogg from '../assets/music/theHub-Tyrelle.ogg';
import HubTye_mp3 from '../assets/music/theHub-Tyrelle.mp3';

import './music.css'; // Assuming your CSS file is named Navbar.css

const tyAlbum = require('assets/images/music_images/shaypeAlbumArt_reduced.webp');
const tyArt = require('assets/images/music_images/tyrelleProfile_reduced.webp');
const mccoyArt = require('assets/images/music_images/McCoyProfile_reduced.webp');

// Define your songs here
const songs = [
  {
    oggSrc: GrasslandTye_ogg,// Default to ogg
    mp3Src: GrasslandTye_mp3,// Fallback to mp3 last resort
    title: 'Grasslands',
    author: 'Tyrelle',
    art: tyAlbum,
    artistImg: tyArt,
  },
  {
    oggSrc: BattleTye_ogg,// Default to ogg
    mp3Src: BattleTye_mp3,// Fallback to mp3 last resort
    title: 'Battle Music',
    author: 'Tyrelle',
    art: tyAlbum,
    artistImg: tyArt,
  },
  {
    oggSrc: HubTye_ogg,// Default to ogg
    mp3Src: HubTye_mp3,// Fallback to mp3 last resorttitle: 'Hub Music',
    author: 'Tyrelle',
    title: 'The Hub',
    art: tyAlbum,
    artistImg: tyArt,
  },
];

const artists = {
  tyrelle: {
    name: 'Tyrelle',
    title: 'Music Artist',
    image: tyArt,
    contacts: [
      { icon: 'twitter', source: '@tyrelle' },
      { icon: 'instagram', source: '@tyrelle' },
    ],
  },
  shaype: {
    name: 'McCoy',
    title: 'Game Soundtrack Artist',
    image: mccoyArt,
    contacts: [
      { icon: 'twitter', source: '@shaype' },
      { icon: 'instagram', source: '@shaype' },
    ],
  },
};

type ArtistKeys = keyof typeof artists; // This will infer 'tyrelle' | 'shaype'


interface MusicPlayerProps {
  startIndex?: number; // Accept an optional startIndex prop
}

const MusicPlayer: React.FC<MusicPlayerProps> = ({ startIndex }) => {
  const wavesurferRefs = useRef<(WaveSurfer | null)[]>([]);
  const [isPlaying, setIsPlaying] = useState<boolean[]>(Array(songs.length).fill(false));
  const [duration, setDuration] = useState<number[]>(Array(songs.length).fill(0));
  const [currentTime, setCurrentTime] = useState<number[]>(Array(songs.length).fill(0));
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentArtist, setCurrentArtist] = useState<any>(null); // Use `any` temporarily for flexibility


  //const displayedSongs = startIndex !== undefined ? [songs[startIndex]] : songs; // Determine songs to display based on startIndex
  const displayedSongs = useMemo(() => {
    return startIndex !== undefined ? [songs[startIndex]] : songs;
  }, [startIndex, songs]);


  useEffect(() => {
    // Intersection Observer for lazy loading songs
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const targetElement = entry.target as HTMLElement;
            const index = parseInt(targetElement.dataset.index || "0", 10); // Fetch the index from data attribute

            console.log("Initializing WaveSurfer for index:", index);

            // Clear existing waveform instance if any
            if (wavesurferRefs.current[index]) {
              wavesurferRefs.current[index]?.destroy(); // Safely destroy the previous instance
            }

            initializeWaveSurfer(index); // Initialize WaveSurfer when in view
            observer.unobserve(entry.target); // Stop observing once loaded
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the song element is visible
    );

    // Lazy loading function using IntersectionObserver
    const initializeWaveSurfer = (index: number) => {
      const containerId = `#waveform${index}`;
      const waveformContainer = document.querySelector(containerId);

      if (!waveformContainer) {
        console.error(`Waveform container not found for index ${index}`);
        return;
      }

      // Display "Loading..." before the waveform loads
      const loadingPlaceholder = document.createElement('div');
      loadingPlaceholder.innerText = "Loading...";
      waveformContainer.appendChild(loadingPlaceholder);

      // Initialize WaveSurfer for the unique song element
      const wavesurfer = WaveSurfer.create({
        container: containerId, // Unique ID for each waveform
        waveColor: '#656666',
        progressColor: '#EE772F',
        barWidth: 2,
      });

      wavesurferRefs.current[index] = wavesurfer; // Store each instance

      wavesurfer.on('ready', () => {
        loadingPlaceholder.remove(); // Remove the loading placeholder
      });

      wavesurfer.on('error', (err) => {
        console.error(`Error loading audio for track ${index}: ${err}`);
        loadingPlaceholder.innerText = "Audio format not supported";
      });

      // Multiple audio sources with fallback support
      const supportedAudioSources = [
        { src: displayedSongs[index].oggSrc, type: 'audio/ogg' }, // Ogg first
        { src: displayedSongs[index].mp3Src, type: 'audio/mp3' }, // Mp3 fallback
      ].filter(source => source.src); // Filter out invalid sources

      let audioLoaded = false;

      // Try to load sources
      supportedAudioSources.forEach((source) => {
        if (!audioLoaded) {
          try {
            wavesurfer.load(source.src); // Try loading the audio
            console.log(`Successfully loaded ${source.type} for track ${index}`);
            audioLoaded = true; // Mark as loaded
          } catch (err) {
            console.error(`Failed to load ${source.type} for track ${index}: ${err}`);
          }
        }
      });

      // Continuously update the time
      wavesurfer.on('audioprocess', (time) => {
        setCurrentTime((prev) => {
          const newCurrentTimes = [...prev];
          newCurrentTimes[index] = time;
          return newCurrentTimes;
        });
      });

      // Update duration when decoded
      wavesurfer.on('decode', (duration) => {
        setDuration((prev) => {
          const newDurations = [...prev];
          newDurations[index] = duration;
          return newDurations;
        });
      });

      if (waveformContainer) {
        const clickHandler = (event: Event) => {
          const mouseEvent = event as MouseEvent; // Cast to MouseEvent
          const offsetX = mouseEvent.offsetX;
          const waveformWidth = waveformContainer.clientWidth;
          const clickPositionRatio = offsetX / waveformWidth;
          const seekTime = clickPositionRatio * wavesurfer.getDuration();
          wavesurfer.seekTo(clickPositionRatio);
          setCurrentTime((prev) => {
            const newCurrentTimes = [...prev];
            newCurrentTimes[index] = seekTime;
            return newCurrentTimes;
          });
        };
        waveformContainer.addEventListener('click', clickHandler as EventListener);

        return () => {
          waveformContainer.removeEventListener('click', clickHandler as EventListener);
        };
      }
    };

    // Observe each song element for lazy loading
    displayedSongs.forEach((_, index) => {
      const songElement = document.querySelector(`#waveform${index}`);
      if (songElement) {
        songElement.setAttribute('data-index', index.toString()); // Assign unique index
        console.log(`Observing song element #waveform${index}`);
        observer.observe(songElement); // Start observing each song element
      } else {
        console.warn(`Waveform element #waveform${index} not found`);
      }
    });

    return () => {
      observer.disconnect(); // Cleanup the observer on unmount
      wavesurferRefs.current.forEach((ws) => {
        if (ws) {
          ws.destroy(); // Cleanup WaveSurfer instances on unmount
        }
      });
    };
  }, [displayedSongs]);
  
  
  
  
  
  
  
  const onPlayPause = (index: number) => {
    const wavesurfer = wavesurferRefs.current[index];
  
    if (wavesurfer) {
      // Check if another track is currently playing
      const currentlyPlayingIndex = isPlaying.findIndex((playing) => playing);
  
      if (currentlyPlayingIndex !== -1 && currentlyPlayingIndex !== index) {
        // Pause the currently playing track
        const currentlyPlayingWavesurfer = wavesurferRefs.current[currentlyPlayingIndex];
        if (currentlyPlayingWavesurfer) {
          currentlyPlayingWavesurfer.pause(); // Pause the current track
          setIsPlaying((prev) => {
            const newPlayingStates = [...prev];
            newPlayingStates[currentlyPlayingIndex] = false; // Update the state to reflect that this track is paused
            return newPlayingStates;
          });
        }
      }
  
      // Toggle play/pause for the selected track
      if (wavesurfer.isPlaying()) {
        wavesurfer.pause();
      } else {
        wavesurfer.play();
      }
  
      setIsPlaying((prev) => {
        const newPlayingStates = [...prev];
        newPlayingStates[index] = !newPlayingStates[index]; // Toggle the current track's playing state
        return newPlayingStates;
      });
    }
  };

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const secondsRemainder = Math.round(seconds) % 60;
    const paddedSeconds = `0${secondsRemainder}`.slice(-2);
    return `${minutes}:${paddedSeconds}`;
  };

  const handleArtistClick = (artistKey: ArtistKeys) => {
    setCurrentArtist(artists[artistKey]);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="song-list">
      {displayedSongs.map((song, index) => (
      <div className="song-element" key={index}>
        <img className="song-art" src={song.art} alt={`${song.title} Art`} />
        <div className="song-player">
          <div className="artist-element">
            <div >
              <h4 style={{ fontWeight: 100, margin: 0}} >{song.author}</h4>
              <h2 style={{ fontWeight: 100, margin: 0}} >{song.title}</h2>
            </div>
            <img className="song-artist" src={song.artistImg} alt={`${song.author} Profile`} onClick={() => handleArtistClick(song.author.toLowerCase() as ArtistKeys)} style={{ cursor: 'pointer' }} />
          </div>
          
          <div className="waveform" id={`waveform${index}`}></div> {/* Unique ID for each waveform */}
          <div className="player-stats">
            <div>
            <button onClick={() => onPlayPause(index)}>
              {isPlaying[index] ? (
                <i className="fa-solid fa-pause"></i> ) : ( <i className="fa-solid fa-play"></i>
                )}
            </button>
            </div>
            <div>
              <span>{formatTime(currentTime[index])}</span> / <span>{formatTime(duration[index])}</span>
            </div>
          </div>
          
        </div>
      </div>
      ))}

      {isPopupOpen && currentArtist && ( // Render ArtistPopup if open and artist data is available
        <ArtistPopup artist={currentArtist} onClose={closePopup} />
      )}
    </div>   
  );
};

export default MusicPlayer;
