// src/pages/Listen.tsx
import React, { useEffect, useState } from 'react';

import Preloader from 'components/preloader';
import Footer from 'components/footer'; // Import the Footer component

import NavBarTop from 'components/navBar_top'; // Import the Footer component
import NavBarMain from 'components/navBar_main'; // Import the Footer component

import MusicPlayer from 'components/music';

const Listen: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulate page-specific loading delay of 2 seconds
    return () => clearTimeout(timer);
  }, []);
  
  return (
  <>
    {loading ? (
      <Preloader />
    ) : (



    <div className="App">
        <NavBarTop theme="solid" /> {/* Add the Navbar component here */}
        <NavBarMain theme="solid" />{/* Add the Navbar component here */} 

        <section className="paragraph info">
            <h1>Listen</h1>
            <p>Below are a few mixes of tracks from our game <a href="https://store.steampowered.com/app/1446340/Shaype/" target="_blank" rel="noopener noreferrer"><b>Shaype</b></a></p>
            <hr className="hr-short" />
            <br></br>
            {/* <section className="paragraph info"> <MusicPlayer /> </section> */} 
            <MusicPlayer /> 
        </section>

        <Footer /> {/* Add the Footer component here */}
    </div>


  )}
  </>
);
};

export default Listen;
