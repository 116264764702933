// teamDetails.tsx
import React from 'react';

import './team.css';

interface Contact {
  icon: string;
  source: string;
}

interface Artist {
  name: string;
  title: string;
  image: string;
  contacts: Contact[];
}

const TeamDetails: React.FC<{ artist: Artist }> = ({ artist }) => {
  return (
      <div className="artist-card">
        <h2>{artist.name}</h2>
        <h3>{artist.title}</h3>
        <img src={artist.image} alt={artist.name} />
        <div className="contacts">
          {artist.contacts.map((contact, idx) => (
            <div key={idx} className="contact">
              <i className={`fa-brands fa-${contact.icon}`}></i>
              <span>{contact.source}</span>
            </div>
          ))}
        </div>
      </div>
  );
};

export default TeamDetails;
